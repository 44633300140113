<template>
  <div class="payOk">
    <div class="main-card">
      <svg
        t="1655800622200"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="4860"
        width="100"
        height="100"
      >
        <path
          d="M287.2 372.6m-32 0a32 32 0 1 0 64 0 32 32 0 1 0-64 0Z"
          fill="#52C41A"
          p-id="4861"
        ></path>
        <path
          d="M736.8 372.6m-32 0a32 32 0 1 0 64 0 32 32 0 1 0-64 0Z"
          fill="#52C41A"
          p-id="4862"
        ></path>
        <path
          d="M513 800.7c-68.4 0-132.7-26.6-181-75-48.4-48.4-75-112.6-75-181 0-17.7 14.3-32 32-32s32 14.3 32 32c0 105.9 86.1 192 192 192s192-86.1 192-192c0-17.7 14.3-32 32-32s32 14.3 32 32c0 68.4-26.6 132.7-75 181-48.3 48.4-112.6 75-181 75z"
          fill="#52C41A"
          p-id="4863"
        ></path>
        <path
          d="M512 1024c-69.1 0-136.2-13.5-199.3-40.2C251.7 958 197 921 150 874c-47-47-84-101.7-109.8-162.7C13.5 648.2 0 581.1 0 512s13.5-136.2 40.2-199.3C66 251.7 103 197 150 150c47-47 101.7-84 162.7-109.8C375.8 13.5 442.9 0 512 0c151.1 0 293.7 66.3 391.3 181.8 11.4 13.5 9.7 33.7-3.8 45.1-13.5 11.4-33.7 9.7-45.1-3.8C769 122 644.2 64 512 64 265 64 64 265 64 512s201 448 448 448 448-201 448-448c0-28.9-2.8-57.8-8.2-85.9-3.4-17.3 8-34.1 25.3-37.5 17.3-3.4 34.1 8 37.5 25.3 6.2 32.1 9.4 65.1 9.4 98.1 0 69.1-13.5 136.2-40.2 199.3C958 772.3 921 827 874 874c-47 47-101.8 83.9-162.7 109.7-63.1 26.8-130.2 40.3-199.3 40.3z"
          fill="#52C41A"
          p-id="4864"
        ></path>
      </svg>
      <div style="margin: 20px 0 40px; color: #52c41a; font-size: 20px">
        下单成功
      </div>
      <div>
        <el-button
          @click="toPush(1)"
          style="margin-right: 20px"
          size="medium"
          type="primary"
          >继续购物</el-button
        >
        <el-button @click="toPush(2)" size="medium" plain>查看订单</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    toPush(type) {
      if (type == 1) {
        this.$router.replace("/");
      }
      if (type == 2) {
        this.$router.replace("buyListing");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.payOk {
  background: #f5f5f5;
  text-align: center;
  padding: 120px 0 140px;
}
</style>