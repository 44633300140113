import { render, staticRenderFns } from "./payOk.vue?vue&type=template&id=38a987af&scoped=true&"
import script from "./payOk.vue?vue&type=script&lang=js&"
export * from "./payOk.vue?vue&type=script&lang=js&"
import style0 from "./payOk.vue?vue&type=style&index=0&id=38a987af&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a987af",
  null
  
)

export default component.exports